import axios from "axios";



import * as formatValue from '../functions/formatValue.js'

export const baseURL = process.env.NODE_ENV==="production" ? "https://oomnielabs.com/oomnielabs-app-server" : "http://localhost:8001"

// export const baseURL = "https://oomnielabs.com/node"
export const serverConnection = axios.create({
  baseURL,
})


export const images = "https://oomnielabs.s3.us-west-2.amazonaws.com/code/apps/oomnielabs/images"
export const icons = "https://oomnielabs.s3.us-west-2.amazonaws.com/code/apps/oomnielabs/icons"


// Run python app
export const pythonApp = async (app_name, main_function, parameters) =>{

  console.log(app_name)
  console.log(main_function)
  console.log(parameters)

    try {
      const response = await serverConnection.post('/runApp', { app_name, main_function, parameters });
      console.log(response); 
      return response.data;
    } catch (error) {
      console.error('Error calling the Python app:', error);
      throw error;  // Optionally rethrow the error for further handling
  }
  }

//General Query
export const getData = async (query)=>{
  try{
    const result = await serverConnection.post("/db/query",{query})
    //console.log(result)
    const data = await result.data
    return (data)
  }catch(error){
    console.log(error)
  }
}


//Get Table
export const getTable = async (tableName)=>{
  
    try{
      const response = await serverConnection.post(`/db/table`,{tableName})
      return (response.data)
    }catch(error){
      console.log(error)
    }
  }

//Create New Record
export const addRecord = async (tableName, columnValues)=>{
  
  if(tableName.length > 0 && Object.entries(columnValues).length>0){
    try{
      const result = await serverConnection.post("/db/addRecord",{tableName, columnValues})
      console.log(result)
      const data = await result.data
      return (data)
    }catch(error){
      console.log(error)
    }
  }else{
    alert("Please provide information for the new record")
  }
}

//Update Record
export const updateRecord = async (tableName, columnValues, conditions)=>{
    try{
      const result = await serverConnection.post("/db/updateRecord",{tableName, columnValues, conditions})
      return result.data
    }catch(error){
      console.log(error)
    }
}

//Delete Record
export const deleteRecord = async (tableName,condition)=>{

  try{
    const result = await serverConnection.post("/db/deleteRecord",{tableName,condition})
    //console.log(result)
    const data = await result.data
    return (data)
  }catch(error){
    //console.log(error)
  }
}


//Get list of all tables in database:
export const getAllTables = async()=>{
  const query= `SELECT table_name FROM information_schema.tables where table_schema = 'public';`
  try{
    const result = await serverConnection.post("/db/query",{query})
    return result.data
  }catch(error){
    console.log(error)
  }
}

// show columsn
export const getColumnData = async(tableName)=>{

  const query= `SELECT column_name as name, data_type FROM information_schema.COLUMNS where TABLE_NAME = N'${tableName}';`
  try{
    const result = await serverConnection.post("/db/query",{query})
    const data = result.data

    let fieldList = [] 
      data.map(item=>{
        fieldList.push(item.name)
      })
    return ({data: data, fieldList:fieldList})
  }catch(error){
    console.log(error)
  }
}



//Authenticate User
export const authenticateUser = async (params)=>{

  // console.log(params)
  try{
    const submitLoggin = await serverConnection.post("/db/authenticateUser",params)
    const userValidated = submitLoggin.data
    return userValidated
  }catch(error){
      console.log(error)
  }
}

//Get User Info
export const getUserInfo = async (username)=>{
  // console.log(username)
  try{
    const getUserQuery = await serverConnection.post("/db/userRecord",{username})
    // console.log(getUserQuery)
    const getUserQueryResonse = await getUserQuery.data;
    return getUserQueryResonse
  }catch(error){
    console.log(error)
  }
}

//Reset User Password
export const addUser = async (params)=>{

  try{
    const result = await serverConnection.post("/db/addUser",{params})
    //console.log(result)
    const data = await result.data
    return (data)
  }catch(error){
    //console.log(error)
  }
}


//Reset User Password
export const resetPassword = async (req)=>{

  const params = {
    tableName: req.tableName,
    idField: req.idField,
    recordId: req.recordId,
    formData: req.formData
  }

  try{
    const result = await serverConnection.post("/db/updateRecord",{params})
    //console.log(result)
    const data = await result.data
    return (data)
  }catch(error){
    //console.log(error)
  }
}


//Send Email
export const sendEmail = async (to, subject, message, htmlPage)=>{
    
  try{
    const result = await serverConnection.post("/sendEmail",{to, subject, message, htmlPage})
    // console.log(result)
    const data = await result.data
    return (data)
  }catch(error){
    // console.log(error)
  }
}

//Ask GPT
export const askGPT = async (prompt)=>{

  try{
    const response = await serverConnection.post("/openai/chatgpt",{prompt})
    return (response.data)
  }catch(error){
    console.log(error)
  }
}


//Generate Image
export const generateImage = async (prompt)=>{

  try{
    const result = await serverConnection.post("/openai/dalle/image",{prompt})
    // console.log(result)
    return (result.data[0].url)
  }catch(error){
    // console.log(error)
  }
}


//Scan Document
export const scanInvoice = async (documentText, record)=>{
  
  const prompt = `The following is an invoice received from a supplier: ${documentText}. Fill in the values in this javascript object: ${JSON.stringify(record)} based on the information in the invoice. Leave a value blank if it can not be determined based on the invoice document received. Return response as javascript object. Be sure to return a properly structured json object with closed brackets and array sub elements if needed.`

  try{
    const result = await serverConnection.post("/openai/chatgpt",{prompt})
    return (JSON.parse(result.data))
  }catch(error){
    // console.log(error)
  }
}




export const convertAudioToText = async (audioBlob) => {

  console.log('audioBlob:', audioBlob); // Log audioBlob to check its content

// Create a new FormData object
const formData = new FormData();
// Append data to the formData object
formData.append('file', audioBlob, 'audio.wav');

  try {
    const response = await serverConnection.post('/openai/whisper', formData)
    return response.data.text
  } catch (error) {
    console.error('Error sending data to backend:', error);
  }
 
};




//Upload files to AWS S3
export const uploadFiles = async (folder, attachments)=>{

  console.log("folder", folder)
  console.log("attachments",attachments)
  
  let updatedAttachments = []

  try {
      await Promise.all(attachments.map(async (file) => {

        const filePath = `secure_file_transfer/${folder}/${file.name}` 
        
          const getUrl = await serverConnection.post(`/aws/getS3FolderUrl`, {filePath: filePath});
          const url = await getUrl.data;
          const fileUrl = await url.split("?")[0];

          await fetch(url, {
              method: "PUT",
              headers: {
                  "Content-Type": file.type,
              },
              body: file.data
          });

          let updatedFile = {...file, ...{["url"]: fileUrl}};
          delete updatedFile.data
          updatedAttachments.push(updatedFile)
      }));

      return updatedAttachments

     } catch (error) {
        console.error("Error uploading file:", error);
    }

}


export const getFiles = async (folderPath)=>{

  const params = {
    bucketName: "oomnielabs",
    path: folderPath
  }

  try {
      const response = await serverConnection.post(`/aws/getFiles`, params);
      const data = await response.data;
      let files = []
      data.forEach(item => {
        const key = item.file.Key
        const fileName = key.split('/').pop(); // Get the file name
        const fileType = fileName.split('.').pop(); // Get the file type
        if (fileName.length>0){
            files.push({
            name: fileName, 
            type: fileType, 
            size: formatValue.formatFileSize(item.file.Size), 
            last_modified: formatValue.UTCToLocalDateTime(item.file.LastModified), 
            owner: item.file.Owner.DisplayName, 
            url: item.url.split("?")[0],
            key: key, 
            file_data: item.file_data,
            meta_data: item.meta_data
          })
        }
      });

      return files
  } catch (error) {
      console.error("Error uploading file:", error);
  }
}



export const deleteFiles = async (files)=>{

  let status = "OK"
  try{
    await Promise.all(files.map(async (item) => {
      const params = {
        Bucket: "nlightnlabs01",
        Key: item.key,
      }
        const response = await serverConnection.post('/aws/deleteFile', params);
        if(response.statusText !="OK"){
          status = response.statusText
        }
    }));
    return status
  }catch(error){
    console.error("Error deleting file:", error);
    return error
  }
   
}


export const generateDocx = async (generatedDoc) => {

  try {
    const response = await serverConnection.post(
      "/generate-docx",
      { html: generatedDoc },
      {
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'Content-Type': 'application/json'
        },
        responseType: 'blob'  // Ensures response is treated as a blob
      }
    );

    console.log("generatedDoc response", response);

    // Create a blob object from the response data
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    
    // Create a link element and trigger the download manually
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'documentation.docx';
    link.click();

    // Cleanup: Revoke the object URL to release memory
    window.URL.revokeObjectURL(link.href);

  } catch (error) {
    console.error("Error downloading DOCX:", error);
  }

};
